import {
  _dispatch,
  TEMPLATE_LIST_SUCCESS,
  DELETE_TEMPLATE_SUCCESS
} from '../actions/emailtemplate';

const INITIAL_STATE = {status: null, email_template_list: {}};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TEMPLATE_LIST_SUCCESS:
      return _dispatch({ ...state, email_template_list: action.payload}, true, 'emaillist');
      break;
    case DELETE_TEMPLATE_SUCCESS:
      return (() => {
        const data = {...state.email_template_list};
        data.data.splice(action.payload, 1);
        return _dispatch({ ...state, email_template_list: data}, true, 'emaillist');
      })();
      break;
      
    default:
      return state;
  }
}