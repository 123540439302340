import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';
export {_dispatch};
const ROOT_URL = API_ROOT;
var token = "";

export const TEMPLATE_LIST_SUCCESS = 'TEMPLATE_LIST_SUCCESS';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';


export function listEmailTemplates(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/email/listEmailTemplates`,
  });
}
export function listEmailTemplatesSuccess(payload, activePage){
  return {
      type: `TEMPLATE_LIST_SUCCESS`,
      payload: payload,
      activePage
    }
}

export function deleteEmailTemplate(data) {
  return axios({
    method: 'DELETE',
    data: data,
    url: `${ROOT_URL}/email/deleteEmailTemplate`
  });
}

export function deleteEmailTemplateSuccess(payload) {
  return {
    type: DELETE_TEMPLATE_SUCCESS,
    payload: payload
  };
}

export function createEmailTemplate(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/email/createEmailTemplate`
  });
}

export function emailTemplateDetailById(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/email/emailTemplateDetailById`,
  });
}
