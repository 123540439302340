import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';
export {_dispatch};
const ROOT_URL = API_ROOT;
var token = "";

export const BLOG_LIST_SUCCESS = 'BLOG_LIST_SUCCESS';
export const DELETE_BLOG_SUCCESS = 'DELETE_BLOG_SUCCESS';
export const BLOG_CATEGORY_LIST_SUCCESS = 'BLOG_CATEGORY_LIST_SUCCESS';
export const DELETE_BLOG_CATEGORY_SUCCESS = 'DELETE_BLOG_CATEGORY_SUCCESS';


export function blogListing(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/bloglisting`,
  });
}
export function blogListingSuccess(payload, activePage){
  return {
      type: `BLOG_LIST_SUCCESS`,
      payload: payload,
      activePage
    }
}

export function deleteBlog(data) {
  return axios({
    method: 'DELETE',
    data: data,
    url: `${ROOT_URL}/admin/deleteblog`
  });
}

export function deleteBlogSuccess(payload) {
  return {
    type: DELETE_BLOG_SUCCESS,
    payload: payload
  };
}

export function addBlog(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/admin/saveblog`
  });
}

export function blogDetailById(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/blogdetailbyid`,
  });
}

export function blogCategoryListing(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/blogcategorylisting`,
  });
}
export function blogCategoryListingSuccess(payload, activePage){
  return {
      type: BLOG_CATEGORY_LIST_SUCCESS,
      payload: payload,
      activePage
    }
}

export function deleteBlogCategory(data) {
  return axios({
    method: 'DELETE',
    data: data,
    url: `${ROOT_URL}/admin/deleteblogcategory`
  });
}

export function deleteBlogCategorySuccess(payload) {
  return {
    type: DELETE_BLOG_CATEGORY_SUCCESS,
    payload: payload
  };
}

export function addBlogCateogry(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/admin/saveblogcategory`
  });
}

export function editBlogCateogry(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/admin/saveblogcategory`
  });
}

export function getBlogCategoryById(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/blogcategorydetailbyid`,
  });
}

export function changeBlogStatus(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/admin/changeblogstatus`
  });
}

export function setFeaturedStatus(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/admin/setfeaturedstatus`
  });
}

export function setTrendingStatus(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/admin/settrendingstatus`
  });
}
