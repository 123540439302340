import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';
export {_dispatch};

export const COMMUNICATION_TYPE_LISTING_SUCCESS = 'COMMUNICATION_TYPE_LISTING_SUCCESS';
export const DELETE_COMMUNICATION_TYPE_SUCCESS = 'DELETE_COMMUNICATION_TYPE_SUCCESS';

const ROOT_URL = API_ROOT;
var token = "";


export function communitcationTypeListing(params){
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/getBroadcastNotifications`
  });
}
export function communitcationTypeListingSuccess(payload){
  return {
      type: COMMUNICATION_TYPE_LISTING_SUCCESS,
      payload: payload
    }
}
export function addCommunicationType(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/admin/sendBulkNotification`
  });
}
export function communicationTypeDetailById(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/admin/communicationtypedetailbyid`,
  });
}
export function deleteCommunicationType(data) {
  return axios({
    method: 'DELETE',
    data: data,
    url: `${ROOT_URL}/admin/deleteBroadcastNotification`
    //url: `${ROOT_URL}/serviceprovider/deletecommunicationtype`
  });
}
export function deleteCommunicationTypeSuccess(payload) {
  return {
    type: DELETE_COMMUNICATION_TYPE_SUCCESS,
    payload: payload
  };
}

