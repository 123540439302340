import {
  _dispatch,
  BLOG_LIST_SUCCESS,
  DELETE_BLOG_SUCCESS,
  BLOG_CATEGORY_LIST_SUCCESS,
  DELETE_BLOG_CATEGORY_SUCCESS
} from '../actions/blog';

const INITIAL_STATE = {status: null, blog_list: {}, blog_cat_list: []};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case BLOG_LIST_SUCCESS:
      return _dispatch({ ...state, blog_list: action.payload}, true, 'bloglist');
      break;
    case DELETE_BLOG_SUCCESS:
      return (() => {
        const data = {...state.blog_list};
        data.data.splice(action.payload, 1);
        return _dispatch({ ...state, blog_list: data}, true, 'bloglist');
      })();
      break;

    case BLOG_CATEGORY_LIST_SUCCESS:
      return _dispatch({ ...state, blog_cat_list: action.payload}, true, 'blogcatlist');
      break;
    case DELETE_BLOG_CATEGORY_SUCCESS:
      return (() => {
        const data = [...state.blog_cat_list];
        data.splice(action.payload, 1);
        return _dispatch({ ...state, blog_cat_list: data}, true, 'blogcatlist');
      })();
      break;
      
    default:
      return state;
  }
}