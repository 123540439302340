import {load} from './utilities';
import React from 'react';
import { Redirect } from 'react-router-dom';

export const ROUTES = {
  ROOT: { 
  	path: '/',
  	exact: true,
  	render: () => <Redirect to="/login" />
  },
  DASHBOARD: { 
  	path: '/dashboard',
    component: load('Dashboard'),
    acl: 'Dashboard'
  },
  DASHBOARDANALYTICS: { 
    path: '/dashboard',
    exact: true,
    component: load('setting/Settings'),
    acl: 'Dashboard'
  },
  // SETTING: { 
  //   path: '/dashboard/settings',
  //   exact: true,
  //   component: load('setting/Settings'),
  //   acl: 'ManageSettings'
  // },
  LOGOUT: { 
    path: '/logout',
    component: load('Logout')
  },
  LOGIN: { 
  	path: '/login',
  	exact: true,
    component: load('Login')
  },
  MANAGEFAQ: { 
    path: '/dashboard/managefaq',
    exact: true,
    component: load('faq/ManageFaq'),
    acl: 'ManageFAQs'
  },
  ADDFAQ: { 
    path: '/dashboard/addfaq',
    exact: true,
    component: load('faq/AddFaq'),
    acl: 'ManageFAQs'
  },
  EDITFAQ: { 
    path: '/dashboard/editfaq/:index',
    exact: true,
    component: load('faq/EditFaq'),
    acl: 'ManageFAQs'
  },
  MANAGETESTIMONIAL: { 
    path: '/dashboard/managetestimonial',
    exact: true,
    component: load('testimonial/ManageTestimonial'),
    acl: 'ManageTestimonials' 
  },
  ADDTESTIMONIAL: { 
    path: '/dashboard/addtestimonial',
    exact: true,
    component: load('testimonial/AddTestimonial'),
    acl: 'ManageTestimonials' 
  },
  EDITTESTIMONIAL: { 
    path: '/dashboard/edittestimonial/:index',
    exact: true,
    component: load('testimonial/EditTestimonial'),
    acl: 'ManageTestimonials' 
  },
  MANAGEPAGES: { 
    path: '/dashboard/managepage',
    exact: true,
    component: load('common/ManagePage'),
    acl: 'UsersListing'
  },
  ADDPAGES: { 
    path: '/dashboard/addpage',
    exact: true,
    component: load('common/AddPage'),
    acl: 'UsersListing'
  },
  EDITPAGES: { 
    path: '/dashboard/editpage/:index',
    exact: true,
    component: load('common/EditPage'),
    acl: 'UsersListing'
  },
  CONTACTUSINFO: { 
    path: '/dashboard/contactusinfo',
    exact: true,
    component: load('users/ContactusInfo'),
    acl: 'UsersListing'
  },
   MANAGECOUNTRYCURRENCY: { 
    path: '/dashboard/managecountrycurrency',
    exact: true,
    component: load('common/ManageCountryCurrency'),
    acl: 'ManageSettings' 
  },
  ADDCOUNTRYCURRENCY: { 
    path: '/dashboard/addcountrycurrency',
    exact: true,
    component: load('common/AddCountryCurrency'),
    acl: 'ManageSettings' 
  },
  EDITCOUNTRYCURRENCY: { 
    path: '/dashboard/editcountrycurrency/:index',
    exact: true,
    component: load('common/EditCountryCurrency'),
    acl: 'ManageSettings' 
  },
  NOTFOUND: { 
    component: load('Sidebar')
  }
};