//import {confirmAlert} from 'react-confirm-alert';
//import 'react-confirm-alert/src/react-confirm-alert.css';
export const APP_VERSION = '1.0';

//live
export const API_ROOT = 'https://api.empireremit.com';
export const API_URL = 'https://api.empireremit.com';
export const APP_DOMAIN = 'https://api.empireremit.com';
export const BASE_URL = 'https://api.empireremit.com';
export const BASE_URL_LIVE = 'https://api.empireremit.com';

// export const API_ROOT = 'http://54.176.169.179:3013';
// export const API_URL = 'http://54.176.169.179:3013';
// export const APP_DOMAIN = 'http://54.176.169.179:3013';
// export const BASE_URL = 'http://54.176.169.179:3013';
// export const BASE_URL_LIVE = 'http://54.176.169.179:3013';

export const WEBSITE_TITLE = "Empireremit";
export const DELETE_CONFIRMATION = "Do you really want to delete this!";
export const DELETE_SUCCESS = "Record has been deleted successfully";
export const ACTIVATE_SUCCESS = "Item has been activated successfully";
export const DEACTIVATE_SUCCESS = "Item has been deactivated successfully";
export const ACTIVATE_CONFIRMATION = "Do you want to activate this record ?";
export const DEACTIVATE_CONFIRMATION = "Do you want to deactivate?";

export const CURRENCY = 'NGN ';

export const KYC_USER_TYPE = [
  { value: '1', label: 'Individual user' },
  { value: '2', label: 'Business User' },
];

export const TRANSFER_TYPE = [
  { value: '1', label: 'Sell Currency' },
  { value: '2', label: 'FX' },
];

export const ACCOUNT_TYPE = [
  { value: '1', label: 'Saving' },
  { value: '2', label: 'Current' },
];

export const CURRENCY_ACTION_TYPE = [
  { value: 1, label: 'Add' },
  { value: 2, label: 'Subtract' }
];

export const ADMIN_PAYMENT_STATUS = [
  { value: '0', label: 'Pending' },
  { value: '1', label: 'Complete' },
  { value: '2', label: 'Failed' }
];

export const CURRENCY_LIST = [
  { value: 'NGN', label: 'Naira', 'sign': '₦' },
  { value: 'USD', label: 'USD', 'sign': '$' },
  { value: 'CNY', label: 'CNY', 'sign': '¥' },
  { value: 'AUD', label: 'AUD', 'sign': 'AUD$' },
  { value: 'KES', label: 'KES', 'sign': 'KSh' },
  { value: 'TZS', label: 'TZS', 'sign': 'TZS' },
  { value: 'XOF', label: 'XOF', 'sign': 'XOF' },
  { value: 'RWF', label: 'RWF', 'sign': 'RWF' },
  { value: 'ZAR', label: 'ZAR', 'sign': 'ZAR' },
  { value: 'AFN', label: 'AFN', 'sign': 'AFN' },
  { value: 'EUR', label: 'EUR', 'sign': 'EUR' },
  { value: 'ALL', label: 'ALL', 'sign': 'ALL' },
  { value: 'DZD', label: 'DZD', 'sign': 'DZD' },
  { value: 'AOA', label: 'AOA', 'sign': 'AOA' },
  { value: 'XCD', label: 'XCD', 'sign': 'XCD' },
  { value: 'ARS', label: 'ARS', 'sign': 'ARS' },
  { value: 'AMD', label: 'AMD', 'sign': 'AMD' },
  { value: 'AWG', label: 'AWG', 'sign': 'AWG' },
  { value: 'SHP', label: 'SHP', 'sign': 'SHP' },
  { value: 'AZN', label: 'AZN', 'sign': 'AZN' },
  { value: 'BSD', label: 'BSD', 'sign': 'BSD' },
  { value: 'BHD', label: 'BHD', 'sign': 'BHD' },
  { value: 'BDT', label: 'BDT', 'sign': 'BDT' },
  { value: 'BBD', label: 'BBD', 'sign': 'BBD' },
  { value: 'BYN', label: 'BYN', 'sign': 'BYN' },
  { value: 'BZD', label: 'BZD', 'sign': 'BZD' },
  { value: 'XOF', label: 'XOF', 'sign': 'XOF' },
  { value: 'BMD', label: 'BMD', 'sign': 'BMD' },
  { value: 'BTN', label: 'BTN', 'sign': 'BTN' },
  { value: 'BOB', label: 'BOB', 'sign': 'BOB' },
  { value: 'BAM', label: 'BAM', 'sign': 'BAM' },
  { value: 'BWP', label: 'BWP', 'sign': 'BWP' },
  { value: 'BRL', label: 'BRL', 'sign': 'BRL' },
  { value: 'BND', label: 'BND', 'sign': 'BND' },
  { value: 'BGN', label: 'BGN', 'sign': 'BGN' },
  { value: 'XOF', label: 'XOF', 'sign': 'XOF' },
  { value: 'BIF', label: 'BIF', 'sign': 'BIF' },
  { value: 'CVE', label: 'CVE', 'sign': 'CVE' },
  { value: 'KHR', label: 'KHR', 'sign': 'KHR' },
  { value: 'XAF', label: 'XAF', 'sign': 'XAF' },
  { value: 'CAD', label: 'CAD', 'sign': 'CAD' },
  { value: 'KYD', label: 'KYD', 'sign': 'KYD' },
  { value: 'NZD', label: 'NZD', 'sign': 'NZD' },
  { value: 'CLP', label: 'CLP', 'sign': 'CLP' },
  { value: 'COP', label: 'COP', 'sign': 'COP' },
  { value: 'KMF', label: 'KMF', 'sign': 'KMF' },
  { value: 'CDF', label: 'CDF', 'sign': 'CDF' },
  { value: 'CRC', label: 'CRC', 'sign': 'CRC' },
  { value: 'HRK', label: 'HRK', 'sign': 'HRK' },
  { value: 'CUP', label: 'CUP', 'sign': 'CUP' },
  { value: 'ANG', label: 'ANG', 'sign': 'ANG' },
  { value: 'GHS', label: 'GHS', 'sign': 'GHS' },
  { value: 'CZK', label: 'CZK', 'sign': 'CZK' },
  { value: 'DKK', label: 'DKK', 'sign': 'DKK' },
  { value: 'DJF', label: 'DJF', 'sign': 'DJF' },
  { value: 'DOP', label: 'DOP', 'sign': 'DOP' },
  { value: 'EGP', label: 'EGP', 'sign': 'EGP' },
  { value: 'ERN', label: 'ERN', 'sign': 'ERN' },
  { value: 'SZL', label: 'SZL', 'sign': 'SZL' },
  { value: 'ETB', label: 'ETB', 'sign': 'ETB' },
  { value: 'FKP', label: 'FKP', 'sign': 'FKP' },
  { value: 'FJD', label: 'FJD', 'sign': 'FJD' },
  { value: 'XPF', label: 'XPF', 'sign': 'XPF' },
  { value: 'GEL', label: 'GEL', 'sign': 'GEL' },
  { value: 'GIP', label: 'GIP', 'sign': 'GIP' },
  { value: 'GTQ', label: 'GTQ', 'sign': 'GTQ' },
  { value: 'GGP', label: 'GGP', 'sign': 'GGP' },
  { value: 'GNF', label: 'GNF', 'sign': 'GNF' },
  { value: 'GYD', label: 'GYD', 'sign': 'GYD' },
  { value: 'HTG', label: 'HTG', 'sign': 'HTG' },
  { value: 'HNL', label: 'HNL', 'sign': 'HNL' },
  { value: 'HKD', label: 'HKD', 'sign': 'HKD' },
  { value: 'HUF', label: 'HUF', 'sign': 'HUF' },
  { value: 'ISK', label: 'ISK', 'sign': 'ISK' },
  { value: 'INR', label: 'INR', 'sign': 'INR' },
  { value: 'IDR', label: 'IDR', 'sign': 'IDR' },
  { value: 'XDR', label: 'XDR', 'sign': 'XDR' },
  { value: 'IRR', label: 'IRR', 'sign': 'IRR' },
  { value: 'IQD', label: 'IQD', 'sign': 'IQD' },
  { value: 'IMP', label: 'IMP', 'sign': 'IMP' },
  { value: 'ILS', label: 'ILS', 'sign': 'ILS' },
  { value: 'JMD', label: 'JMD', 'sign': 'JMD' },
  { value: 'JPY', label: 'JPY', 'sign': 'JPY' },
  { value: 'JEP', label: 'JEP', 'sign': 'JEP' },
  { value: 'JOD', label: 'JOD', 'sign': 'JOD' },
  { value: 'KZT', label: 'KZT', 'sign': 'KZT' },
  { value: 'KES', label: 'KES', 'sign': 'KES' },
  { value: 'KWD', label: 'KWD', 'sign': 'KWD' },
  { value: 'KGS', label: 'KGS', 'sign': 'KGS' },
  { value: 'LAK', label: 'LAK', 'sign': 'LAK' },
  { value: 'LBP', label: 'LBP', 'sign': 'LBP' },
  { value: 'LSL', label: 'LSL', 'sign': 'LSL' },
  { value: 'LRD', label: 'LRD', 'sign': 'LRD' },
  { value: 'LYD', label: 'LYD', 'sign': 'LYD' },
  { value: 'CHF', label: 'CHF', 'sign': 'CHF' },
  { value: 'MOP', label: 'MOP', 'sign': 'MOP' },
  { value: 'MGA', label: 'MGA', 'sign': 'MGA' },
  { value: 'MWK', label: 'MWK', 'sign': 'MWK' },
  { value: 'MYR', label: 'MYR', 'sign': 'MYR' },
  { value: 'MVR', label: 'MVR', 'sign': 'MVR' },
  { value: 'MRU', label: 'MRU', 'sign': 'MRU' },
  { value: 'MUR', label: 'MUR', 'sign': 'MUR' },
  { value: 'MXN', label: 'MXN', 'sign': 'MXN' },
  { value: 'MDL', label: 'MDL', 'sign': 'MDL' },
  { value: 'MNT', label: 'MNT', 'sign': 'MNT' },
  { value: 'MAD', label: 'MAD', 'sign': 'MAD' },
  { value: 'MZN', label: 'MZN', 'sign': 'MZN' },
  { value: 'MMK', label: 'MMK', 'sign': 'MMK' },
  { value: 'NAD', label: 'NAD', 'sign': 'NAD' },
  { value: 'NPR', label: 'NPR', 'sign': 'NPR' },
  { value: 'NIO', label: 'NIO', 'sign': 'NIO' },
  { value: 'KPW', label: 'KPW', 'sign': 'KPW' },
  { value: 'MKD', label: 'MKD', 'sign': 'MKD' },
  { value: 'NOK', label: 'NOK', 'sign': 'NOK' },
  { value: 'OMR', label: 'OMR', 'sign': 'OMR' },
  { value: 'PKR', label: 'PKR', 'sign': 'PKR' },
  { value: 'PGK', label: 'PGK', 'sign': 'PGK' },
  { value: 'PYG', label: 'PYG', 'sign': 'PYG' },
  { value: 'PEN', label: 'PEN', 'sign': 'PEN' },
  { value: 'PHP', label: 'PHP', 'sign': 'PHP' },
  { value: 'PLN', label: 'PLN', 'sign': 'PLN' },
  { value: 'QAR', label: 'QAR', 'sign': 'QAR' },
  { value: 'RON', label: 'RON', 'sign': 'RON' },
  { value: 'RUB', label: 'RUB', 'sign': 'RUB' },
  { value: 'WST', label: 'WST', 'sign': 'WST' },
  { value: 'STN', label: 'STN', 'sign': 'STN' },
  { value: 'SAR', label: 'SAR', 'sign': 'SAR' },
  { value: 'RSD', label: 'RSD', 'sign': 'RSD' },
  { value: 'SCR', label: 'SCR', 'sign': 'SCR' },
  { value: 'SLL', label: 'SLL', 'sign': 'SLL' },
  { value: 'SGD', label: 'SGD', 'sign': 'SGD' },
  { value: 'SOS', label: 'SOS', 'sign': 'SOS' },
  { value: 'SBD', label: 'SBD', 'sign': 'SBD' },
  { value: 'GBP', label: 'GBP', 'sign': 'GBP' },
  { value: 'KRW', label: 'KRW', 'sign': 'KRW' },
  { value: 'SSP', label: 'SSP', 'sign': 'SSP' },
  { value: 'LKR', label: 'LKR', 'sign': 'LKR' },
  { value: 'SDG', label: 'SDG', 'sign': 'SDG' },
  { value: 'SRD', label: 'SRD', 'sign': 'SRD' },
  { value: 'NOK', label: 'NOK', 'sign': 'NOK' },
  { value: 'SEK', label: 'SEK', 'sign': 'SEK' },
  { value: 'SYP', label: 'SYP', 'sign': 'SYP' },
  { value: 'TWD', label: 'TWD', 'sign': 'TWD' },
  { value: 'TJS', label: 'TJS', 'sign': 'TJS' },
  { value: 'THB', label: 'THB', 'sign': 'THB' },
  { value: 'TOP', label: 'TOP', 'sign': 'TOP' },
  { value: 'TTD', label: 'TTD', 'sign': 'TTD' },
  { value: 'TND', label: 'TND', 'sign': 'TND' },
  { value: 'TRY', label: 'TRY', 'sign': 'TRY' },
  { value: 'TMT', label: 'TMT', 'sign': 'TMT' },
  { value: 'UGX', label: 'UGX', 'sign': 'UGX' },
  { value: 'UAH', label: 'UAH', 'sign': 'UAH' },
  { value: 'AED', label: 'AED', 'sign': 'AED' },
  { value: 'UYU', label: 'UYU', 'sign': 'UYU' },
  { value: 'UZS', label: 'UZS', 'sign': 'UZS' },
  { value: 'VUV', label: 'VUV', 'sign': 'VUV' },
  { value: 'VES', label: 'VES', 'sign': 'VES' },
  { value: 'VND', label: 'VND', 'sign': 'VND' },
  { value: 'YER', label: 'YER', 'sign': 'YER' },
  { value: 'ZMW', label: 'ZMW', 'sign': 'ZMW' }
];


export const KYC_VERFICATION_TYPE = [
  { value: 1, label: 'International Passport' },
  { value: 2, label: 'Driving License' },
  { value: 3, label: 'Medicare Card' }
];

export const KYC_STATUS = [
  { value: 0, label: 'Pending' },
  { value: 1, label: 'Completed' },
  { value: 2, label: 'In progress' },
  { value: 3, label: 'Manually Apply' },
  { value: 4, label: 'Rejected' }
];

export const USER_PAYMENT_STATUS = [
  { value: '0', label: 'Initiate' },
  { value: '1', label: 'Completed' },
  { value: '2', label: 'Failed' },
  { value: '3', label: 'Canceled' }
];

export const PAYMENT_TYPE = [
  { value: '1', label: 'Poli' },
  { value: '2', label: 'Direct Transfer' }
];

export function clearSession(){
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('persist:root');
  window.location = '/';
}


export function verifyAndUpdateAppVersion(){
  // Display App Version
  const version = localStorage.getItem("APP_VERSION");
  if(version === null || version != APP_VERSION){
    localStorage.setItem("APP_VERSION", APP_VERSION);
    clearSession();
  }
}



export function validateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  return (false)
}

export function stripHtmlTags(str, length) {
  if ((str === null) || (str === '')) {
    return false;
  } else {
    str = str.toString();
    str = str.replace(/<[^>]*>/g, '')
    if (length && length > 0 && str.length > length) {
      str = str.substr(0, length);
      str = str + "...";
    }
  }
  return str;
}

export function FromatValidationErrors(errors, error_keys) {
  Object.keys(errors).map(function(key) {
    error_keys[key] = errors[key][0];
    return key;
  });
  return error_keys;
}

export function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function _dispatch(nextState, rerender = false, compName = null) {
  rerender = rerender
    ? new Date().getTime()
    : nextState.status;
  return {
    ...nextState,
    status: rerender,
    compName
  }
}


export function currencyFormat(amount, currency, showCurrencySymbol = true, floatValue = true){

  amount = floatValue ? parseFloat(amount).toFixed(2) : parseInt(amount);

  if(typeof JSON.parse(localStorage.getItem('auth')).user.currencySymbol !== 'undefined' && JSON.parse(localStorage.getItem('auth')).user.currencySymbol !== null){
    currency = JSON.parse(localStorage.getItem('auth')).user.currencySymbol;
  } else {
    currency = '₹';
  }
  
  return `${currency}${amount}`;
}

export function notifyLink(type, subjectId=0){
  var newlink = '';
  if(type == 1){
    newlink = '/dashboard/unassignedorders';
  } else if(type == 2 || type == 3){
    newlink = '/dashboard/requestedmenuitems';
  } else if(type == 4){
    newlink = '/dashboard/shoplisting';
  } else if(type == 5){
    newlink = '/dashboard/riderdocuments/' + subjectId;
  } else if(type == 6){
    newlink = '/dashboard/shopdocuments/' + subjectId;
  } else if(type == 7){
    newlink = '/dashboard/deliveryagentlisting';
  } else if(type == 8){
    newlink = '/dashboard/outofstockorders';
  }
  return newlink;
}



